div.ui.fluid.card.HalinCard {
    border: none;
    min-width: 350px;
    max-width: 460px;
    min-height: 280px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-left: 8px;
    padding-right: 8px;
}

div.ui.fluid.card.HalinCard > div.header {
    padding-top: 10px;
}

div.ui.fluid.card.HalinCard > div.HalinCardContent {
    padding-top: 10px;
    padding-bottom: 10px;
}