div#root {
  height: 100%;
}

.Halin {
  text-align: center;
  width: 100%;
  display: inline;
  background-color: black;
}

/** Mmmmmm.....toast..... */
div.Toastify > div > div {
  border-radius: 20px;
}

#MemberPane > div > div.ui.bottom.attached.segment.active.tab {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

/*
 * Wrapper that's placed around most main content panes
 */
div.ContentPaneComponent {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: 14px;
  height: 100%;
  overflow-y: scroll;
}

.MainBody {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.MainBodyError {
  padding-top: 50px;
  display: block;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.PaneWrapper {
  /* padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px; */
}

.PaneWrapper.primary {
  /* border-left: 1px solid #d4d4d5;
  border-right: 1px solid #d4d4d5;
  border-bottom: 1px solid #d4d4d5; */
}

.Halin-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Halin-header {
  padding-top: 5px;
  /* background-color: #222; */
  /* height: 70px; */
  /* padding: 20px; */
  /* color: white; */
}

.Halin-title {
  font-size: 1.3em;
}

.Halin-intro {
  font-size: large;
}

div.ui.grid {
  /* padding-left: 15px;
  padding-right: 15px; */
}
