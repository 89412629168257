.ui.grid > div.row.CypherTimeseriesLegend {
    padding-bottom: 0px;
}

.ui.grid > div.row.CypherTimeseriesContent {
    padding-top: 0px;
    padding-bottom: 0px;
}

.ui.grid > div.row.CypherTimeseriesContent > div.column {
    padding-left: 0px;
    padding-right: 0px;
}

div.one.column.row.CypherTimeseriesLegend > div.column > div > div > div > div > svg {
    border: 1px solid gainsboro;
    background-color: gainsboro;
    border-radius: 5px;
}