.Advisor {
    /* padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid red;
    margin-bottom: 15px; */
}

.Advisor > a.ui.button.DownloadCSV {
    margin-bottom: 16px;
}