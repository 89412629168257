.TaskDetail {

}

.TaskDetail > div > div.QueryBox {
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: auto;
    text-align: 'left';
    border: 1px solid black;
    font-size: 0.9em;
    margin-top: 16px; 
}